import { crud } from '@/api/authors';

const state = () => ({
	authors: [],
});

const getters = {};

const actions = {
	getAuthors() {
		return crud.all();
	},
	getAuthor(_, authorId) {
		return crud.find(authorId);
	},
	createAuthor(_, payload) {
		return crud.create(payload);
	},
	updateAuthor(_, { authorId, payload }) {
		return crud.update(authorId, payload);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
