import { crud as schoolCrud, detachUser, attachUser, mergeSchools } from '@/api/schools';
import { crud as schoolTeachersCrud } from '@/api/schoolteachers';
import { crud as schoolStudentsCrud } from '@/api/schoolstudents';

const state = () => ({
	filters: [
		// Default search for school list
		{ column: 'school_classes_count', operator: '>', value: 0 },
	],
});

const getters = {};

const actions = {
	getSchools(_, params) {
		return schoolCrud.all(params);
	},
	getSchool(_, schoolId) {
		return schoolCrud.find(schoolId);
	},
	createSchool(_, payload) {
		return schoolCrud.create(payload);
	},
	updateSchool(_, { schoolId, payload }) {
		return schoolCrud.update(schoolId, payload);
	},
	deleteSchool(_, schoolId) {
		return schoolCrud.delete(schoolId);
	},
	getSchoolTeachers(_, { schoolId, includeMaterials }) {
		return schoolTeachersCrud.all({ include_materials: includeMaterials }, { schoolId });
	},
	getSchoolStudents(_, { params, urlParts }) {
		return schoolStudentsCrud.all(params, urlParts);
	},
	detachUser(_, { schoolId, userId }) {
		return detachUser(schoolId, userId);
	},
	attachUser(_, { schoolId, userId, role }) {
		return attachUser(schoolId, userId, role);
	},
	mergeSchools(_, { dstSchoolId, srcSchoolIds, safeword }) {
		return mergeSchools(dstSchoolId, srcSchoolIds, safeword);
	},
};

const mutations = {
	setFilters(state, filters) {
		state.filters = filters;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
