import { updateSectionSitemap } from '@/api/sitemap';

const state = {};

const getters = {};

const actions = {
	updateSectionSitemap(_, payload) {
		return updateSectionSitemap(payload);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
