import { getUser } from '@/api/user';
import { getCachedItem, cacheItem, forgetItem } from '../cache';

const userKey = 'user';

const state = () => ({
	user: getCachedItem(userKey) || null,
});

const getters = {
	user: state => state.user,
};

const actions = {
	getUser({ commit }) {
		return getUser().then(user => {
			commit('setUser', user);
		});
	},
};

const mutations = {
	setUser(state, user) {
		state.user = user;
		cacheItem(userKey, user);
	},
	clearUser(state) {
		state.user = null;
		forgetItem(userKey);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
