import { getReferencesByCourse } from '@/api/references';

const state = {};

const getters = {};

const actions = {
	getReferencesByCourse() {
		return getReferencesByCourse();
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
