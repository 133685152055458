import Pusher from 'pusher-js';
import store from '@/store';

class PusherService {
	/**
	 * @type {Pusher}
	 */
	pusher = null;

	/**
	 *
	 * @param {string} appKey
	 * @param {string} wsHost
	 * @param {string} apiURL
	 */
	init(appKey, wsHost, apiURL) {
		this.pusher = new Pusher(appKey, {
			wsHost: wsHost,
			cluster: 'private',
			forceTLS: 'https:' === document.location.protocol,
			enabledTransports: ['ws', 'wss'],
			encrypted: true,
			disableStats: true,
			authEndpoint: `${apiURL}broadcasting/auth`,
			auth: {
				headers: {
					Authorization: new LazyString(() => `Bearer ${store.getters['auth/token']}`),
				},
			},
		});
	}

	/**
	 *
	 * @param {string} channel
	 * @returns {Pusher.Channel}
	 */
	subscribe(channel) {
		return this.pusher?.subscribe?.(channel);
	}
}

class LazyString {
	/**
	 * Function that returns a string.
	 *
	 * @callback strFn
	 * @returns {string}
	 */

	/**
	 *
	 * @type {strFn}
	 */
	strFn = () => '';

	/**
	 *
	 * @param {strFn} strFn - The callback that handles the response.
	 */
	constructor(strFn) {
		this.strFn = strFn;
	}

	/**
	 *
	 * @returns {string}
	 */
	toString() {
		return this.strFn();
	}
}

export default new PusherService();
