import { crud } from '@/api/news';

const state = {};

const getters = {};

const actions = {
	getAppNewsStories() {
		return crud.all();
	},
	getAppNewsStory(_, storyId) {
		return crud.find(storyId);
	},
	createAppNewsStory(_, payload) {
		return crud.create(payload);
	},
	updateAppNewsStory(_, { storyId, payload }) {
		return crud.update(storyId, payload);
	},
	deleteAppNewsStory(_, storyId) {
		return crud.delete(storyId);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
