<template>
	<main class="has-fixed-header">
		<div class="container is-fluid">
			<router-view></router-view>
		</div>
	</main>
</template>

<script>
export default {
	name: 'SubLayout',
};
</script>
