import { crud as homeworkCrud, restoreSchoolHomework } from '@/api/schoolhomework';

const state = {};

const getters = {};

const actions = {
	getSchoolHomework(_, { params, urlParts }) {
		return homeworkCrud.all(params, urlParts);
	},
	restoreSchoolHomework(_, { schoolId, homeworkId }) {
		return restoreSchoolHomework(schoolId, homeworkId);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
