import http from './http';

export function getInstitutionExportUsers(institutionId, params = {}) {
	return http
		.get(`/admin/unilogin/institution/${institutionId}/export/users`, { params })
		.then(resp => resp.data);
}

export function getInstitutionExportGroups(institutionId, params = {}) {
	return http
		.get(`/admin/unilogin/institution/${institutionId}/export/groups`, { params })
		.then(resp => resp.data);
}
