import { crud, getCategoriesByCourse, getSubCategoriesByCourse, updateWeights } from '@/api/categories';

const state = {};

const getters = {};

const actions = {
	getCategories(_, params) {
		return crud.all(params);
	},
	getCategoriesByCourse() {
		return getCategoriesByCourse();
	},
	getSubCategoriesByCourse(_, courseId) {
		return getSubCategoriesByCourse(courseId);
	},
	getCategory(_, categoryId) {
		return crud.find(categoryId);
	},
	createCategory(_, payload) {
		return crud.create(payload);
	},
	updateCategory(_, { categoryId, payload }) {
		return crud.update(categoryId, payload);
	},
	updateWeights(_, payload) {
		return updateWeights(payload);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
