import { crud, getTooltipsUpdatedSince } from '@/api/tooltips';
import sortBy from 'lodash/sortBy';

const state = () => ({
	tooltips: [],
	loading: false,
	loaded: false,
});

const tooltipTypeWordExplanation = 1;
const tooltipTypeGlossary = 2;

const getters = {
	tooltips: state => sortBy(state.tooltips, 'title'),
	wordExplanationsByCourseId: (_, getters) => courseId => {
		return getters.tooltips.filter(
			item => item.type === tooltipTypeWordExplanation && item.course_id === courseId,
		);
	},
	glossariesByCourseId: (_, getters) => courseId => {
		return getters.tooltips.filter(
			item => item.type === tooltipTypeGlossary && item.course_id === courseId,
		);
	},
};

const actions = {
	getTooltips({ state, commit }) {
		if (state.loading) {
			return;
		}

		commit('setLoading');

		return crud.all().then(({ data }) => {
			commit('setTooltips', data);
			return data.slice(0);
		});
	},
	refreshTooltips({ state, commit }) {
		if (state.loading) {
			return Promise.resolve();
		}
		const mostRecentTooltip = state.tooltips.length ? state.tooltips[0].updated_at : null;

		commit('setLoading');

		return getTooltipsUpdatedSince(mostRecentTooltip).then(tooltips => {
			commit('updateTooltips', tooltips);
		});
	},
	getTooltip(_, tooltipId) {
		return crud.find(tooltipId);
	},
	createTooltip(_, payload) {
		return crud.create(payload);
	},
	updateTooltip(_, { tooltipId, payload }) {
		return crud.update(tooltipId, payload);
	},
};

const mutations = {
	setLoading(state) {
		state.loading = true;
		state.loaded = false;
	},
	setTooltips(state, tooltips) {
		state.tooltips = tooltips;
		state.loaded = true;
		state.loading = false;
	},
	updateTooltips(state, tooltips) {
		tooltips.forEach(tooltip => {
			const index = state.tooltips.findIndex(t => t.id === tooltip.id);
			if (index >= 0) {
				state.tooltips[index] = tooltip;
			} else {
				state.tooltips.push(tooltip);
			}
		});
		state.loaded = true;
		state.loading = false;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
