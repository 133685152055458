import http from './http';

export function getUserActivity(userId) {
	return http.get(`/admin/users/${userId}/activity`).then(resp => resp.data.data);
}

export function getSchoolInsights(params) {
	return http.get('/admin/schools/insights', { params }).then(resp => resp.data);
}

export function getTeacherInsights(schoolId, params) {
	return http.get(`/admin/schools/insights/${schoolId}/teachers`, { params }).then(resp => resp.data);
}
