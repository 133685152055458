<template>
	<div>
		<component :is="errorComponent" v-if="errorComponent" :err="firstError" />
		<slot v-else></slot>
	</div>
</template>

<script>
import Router from 'vue-router';
import { ExtendableError, NotFoundError, UnauthorizedError, RequestAbortedError } from '@/api/errors';
import { shouldErrorStopPropagation } from '@/utils/error';
import NotFound from '@/components/errors/NotFound';

export default {
	name: 'ErrorListener',
	components: {
		NotFound,
	},
	data() {
		return {
			errors: [],
		};
	},
	computed: {
		errorComponent() {
			if (this.firstError instanceof NotFoundError) {
				return 'NotFound';
			}
			return null;
		},
		firstError() {
			return this.errors.length ? this.errors[0] : null;
		},
		hasMultipleErrors() {
			return this.errors.length > 1;
		},
	},
	watch: {
		$route() {
			this.reset();
		},
	},
	mounted() {
		window.addEventListener('unhandledrejection', this.onUnhandledPromiseRejection);
	},
	beforeDestroy() {
		window.removeEventListener('unhandledrejection', this.onUnhandledPromiseRejection);
	},
	methods: {
		onUnhandledPromiseRejection(event) {
			// event is a PromiseRejectionEvent
			// See: https://developer.mozilla.org/en-US/docs/Web/API/PromiseRejectionEvent

			const err = event.reason;

			if (shouldErrorStopPropagation(err)) {
				event.stopPropagation();
				event.preventDefault();
			}

			if (shouldIgnore(err)) {
				return;
			}

			this.errors.push(err);

			if (!this.errorComponent || this.hasMultipleErrors) {
				this.$buefy.toast.open({
					message: err.message,
					type: 'is-danger',
				});
			}
		},
		reset() {
			this.errors = [];
		},
	},
};

function shouldIgnore(err) {
	return (
		!(err instanceof ExtendableError) ||
		err instanceof UnauthorizedError ||
		err instanceof RequestAbortedError ||
		Router.isNavigationFailure(err)
	);
}
</script>
