import axios from 'axios';
import http from './http';

export function getAppConfig() {
	return axios.get('/config.json').then(resp => resp.data);
}

export function doAppSearch(params) {
	return http.get('/admin/search', { params }).then(resp => resp.data);
}

export function getLixToken() {
	return http.post('/lix/token').then(resp => resp.data);
}
