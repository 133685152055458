import http from './http';
import CrudApi from './crud';

export const crud = new CrudApi('/admin/sectionrelations', { only: ['index', 'show', 'update', 'destroy'] });

export function getSectionRelationActions(sectionRelationId) {
	return http.get(`/admin/sectionrelations/${sectionRelationId}/actions`).then(({ data }) => data.data);
}

export function sectionRelationDetachCheck(sectionRelationId) {
	return http.get(`/admin/sectionrelations/${sectionRelationId}/detach`).then(({ data }) => data.data);
}

export function detachSectionRelation(sectionRelationId) {
	return http.delete(`/admin/sectionrelations/${sectionRelationId}`).then(({ data }) => data.data);
}

export function attachSectionRelation(sectionRelationId, payload) {
	return http.post(`/admin/sectionrelations/${sectionRelationId}/attach`, payload).then(({ data }) => data.data);
}

export function getExercises(search = '', courseId = null) {
	const params = { search };
	if (courseId) {
		params.course_id = courseId;
	}
	return http.get('/admin/sectionrelations/exercises', { params }).then(({ data }) => data.data);
}
