import http from './http';
import CrudApi from './crud';

export const crud = new CrudApi('/admin/assignments');

export function getAssignment(assignmentId) {
	return http.get(`/admin/assignments/${assignmentId}`).then(resp => resp.data.data);
}

export function uploadImage(assignmentId, payload) {
	return http
		.post(`/admin/assignments/${assignmentId}/image`, payload, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
		.then(resp => resp.data);
}

export function updateWeights(payload) {
	return http.put('/admin/assignments/weights', payload).then(resp => resp.data);
}

export function getSitemap(assignmentId) {
	return http.get(`/admin/assignments/${assignmentId}/sitemap`).then(resp => resp.data.data);
}

export function getContentAnalysis(assignmentId) {
	return http.get(`/admin/assignments/${assignmentId}/contentanalysis`).then(resp => resp.data.data);
}

export function getDurationTotal(assignmentId) {
	return http.get(`/admin/assignments/${assignmentId}/duration-total`).then(resp => resp.data);
}
