import http from './http';

export function getUserToken(userId) {
	return http.get(`/auth/token?userId=${userId}`).then(resp => resp.data);
}

export function login(email, password) {
	return http.post('/auth/adminlogin', { email, password }).then(resp => resp.data);
}

export function forgotPassword(email) {
	return http.post('/password/forgot', { email }).then(resp => resp.data);
}

export function resetPassword(payload) {
	return http.post('/password/reset', payload).then(resp => resp.data);
}
