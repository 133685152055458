import {
	crud as sectionsCrud,
	attachMedia,
	detachMedia,
	attachTask,
	detachTask,
	getTypes,
	attachToParent,
	getExerciseDuration,
} from '@/api/sections';

const state = {};

const getters = {};

const actions = {
	createSection(_, { payload }) {
		return sectionsCrud.create(payload);
	},
	updateSection(_, { sectionId, payload }) {
		return sectionsCrud.update(sectionId, payload);
	},
	attachMedia(_, { sectionId, mediaId, conf }) {
		return attachMedia(sectionId, mediaId, conf);
	},
	detachMedia(_, { sectionId, mediaId }) {
		return detachMedia(sectionId, mediaId);
	},
	attachTask(_, { sectionId, taskId }) {
		return attachTask(sectionId, taskId);
	},
	detachTask(_, { sectionId, taskId }) {
		return detachTask(sectionId, taskId);
	},
	getExerciseDuration(_, { sectionId, courseId }) {
		return getExerciseDuration(sectionId, courseId);
	},
	getTypes() {
		return getTypes();
	},
	attachToParent(
		_,
		{ sectionId, sectionTypeId, sectionRelationsType, sectionRelationsId, sectionRelationId = null },
	) {
		return attachToParent(
			sectionId,
			sectionTypeId,
			sectionRelationsType,
			sectionRelationsId,
			sectionRelationId,
		);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
