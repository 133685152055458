import { crud } from '@/api/tasks';

const state = {};

const getters = {};

const actions = {
	getTasks(_, params) {
		return crud.all(params);
	},
	getTask(_, taskId) {
		return crud.find(taskId);
	},
	updateTask(_, { taskId, payload }) {
		return crud.update(taskId, payload);
	},
	createTask(_, { payload }) {
		return crud.create(payload);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
