import { getCachedItem, cacheItem, forgetItem } from '../localCache';
import dayjs from 'dayjs';

const SINCE_CACHE_KEY = 'crmSince';
const UNTIL_CACHE_KEY = 'crmUntil';
const EDUCATIONS_CACHE_KEY = 'crmEducations';
const COURSES_CACHE_KEY = 'crmCourses';

const state = () => ({
	since: getCachedItem(SINCE_CACHE_KEY) ? dayjs(getCachedItem(SINCE_CACHE_KEY)) : dayjs().subtract(1, 'month'),
	until: getCachedItem(UNTIL_CACHE_KEY) ? dayjs(getCachedItem(UNTIL_CACHE_KEY)) : dayjs(),
	educationIds: getCachedItem(EDUCATIONS_CACHE_KEY) ?? [],
	courseIds: getCachedItem(COURSES_CACHE_KEY) ?? [],
});

const getters = {
	timespan: state => {
		return { since: state.since, until: state.until };
	},
	selectedEducationIds: state => state.educationIds,
	selectedCourseIds: state => state.courseIds,
	educationIsSelected: state => education => state.educationIds.includes(education.id),
	courseIsSelected: state => course => state.courseIds.includes(course.id),
};

const actions = {
	setTimespan({ commit }, { since, until }) {
		commit('setTimespan', { since, until });
	},
	setEducations({ commit }, educations) {
		commit(
			'setEducationIds',
			educations.map(education => education.id),
		);
	},
	setCourses({ commit }, courses) {
		commit(
			'setCourseIds',
			courses.map(course => course.id),
		);
	},
	resetFilters({ commit }) {
		commit('resetTimespan');
		commit('resetEducationIds');
		commit('resetCourseIds');
	},
};

const mutations = {
	setTimespan(state, { since, until }) {
		state.since = since;
		state.until = until;
		cacheItem(SINCE_CACHE_KEY, since);
		cacheItem(UNTIL_CACHE_KEY, until);
	},
	resetTimespan(state) {
		state.since = dayjs().subtract(1, 'month');
		state.until = dayjs();
		forgetItem(SINCE_CACHE_KEY);
		forgetItem(UNTIL_CACHE_KEY);
	},
	setEducationIds(state, educationIds) {
		state.educationIds = educationIds;
		cacheItem(EDUCATIONS_CACHE_KEY, educationIds);
	},
	resetEducationIds(state) {
		state.educationIds = [];
		forgetItem(EDUCATIONS_CACHE_KEY);
	},
	setCourseIds(state, courseIds) {
		state.courseIds = courseIds;
		cacheItem(COURSES_CACHE_KEY, courseIds);
	},
	resetCourseIds(state) {
		state.courseIds = [];
		forgetItem(COURSES_CACHE_KEY);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
