<template>
	<div class="app has-background-white has-navbar-fixed-top">
		<Header />

		<div class="wrapper">
			<slot />
		</div>
	</div>
</template>

<script>
import Header from './Header';

export default {
	name: 'Layout',
	components: { Header },
};
</script>
