import http from './http';
import CrudApi from './crud';

export const crud = new CrudApi('/admin/taxonomy/categories', { only: ['index', 'store', 'update', 'show'] });

export function getCategoriesByCourse() {
	return http.get('/admin/taxonomy/categories/course').then(resp => resp.data);
}

export function getSubCategoriesByCourse(courseId) {
	return http.get(`/admin/taxonomy/categories/course/${courseId}`).then(resp => resp.data);
}

export function updateWeights(payload) {
	return http.put('/admin/taxonomy/categories/weights', payload).then(resp => resp.data);
}
