import http from './http';

export function getAudioFiles(params) {
	return http.get('/admin/audio', { params }).then(resp => resp.data.data);
}

export function getPaginatedAudioFiles(params) {
	return http.get('/admin/audio/paginate', { params }).then(resp => resp.data);
}

export function uploadAudioFile(payload) {
	return http
		.post('/admin/audio', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then(resp => resp.data);
}
