import {
	crud,
	getAssignment,
	uploadImage,
	updateWeights,
	getSitemap,
	getContentAnalysis,
	getDurationTotal,
} from '@/api/assignments';

const state = {};

const getters = {};

const actions = {
	getAssignments() {
		return crud.all();
	},
	getAssignment(_, assignmentId) {
		return getAssignment(assignmentId);
	},
	createAssignment(_, payload) {
		return crud.create(payload);
	},
	updateAssignment(_, { assignmentId, payload }) {
		return crud.update(assignmentId, payload);
	},
	deleteAssignment(_, assignmentId) {
		return crud.delete(assignmentId);
	},
	uploadImage(_, { assignmentId, payload }) {
		return uploadImage(assignmentId, payload);
	},
	updateWeights(_, payload) {
		return updateWeights(payload);
	},
	getSitemap(_, assignmentId) {
		return getSitemap(assignmentId);
	},
	getContentAnalysis(_, assignmentId) {
		console.log(assignmentId);
		return getContentAnalysis(assignmentId);
	},
	getDurationTotal(_, { assignmentId }) {
		return getDurationTotal(assignmentId);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
