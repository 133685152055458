import VueCookie from 'vue-cookie';
import { login, forgotPassword, resetPassword, getUserToken } from '@/api/auth';

const tokenKey = 'token';
const rememberMeDays = 14;

// initial state
const state = () => ({
	token: VueCookie.get(tokenKey) || null,
});

// getters
const getters = {
	isAuthenticated: state => !!state.token,
	token: state => state.token,
};

// actions
const actions = {
	login({ commit }, { email, password, rememberMe }) {
		return login(email, password).then(resp => {
			const token = resp.access_token;
			commit('setToken', { token, rememberMe });
			commit('user/setUser', resp.user, { root: true });
		});
	},
	logout({ commit }) {
		commit('clearToken', null);
		commit('user/clearUser', null, { root: true });
		// Also clear Lix token
		commit('lix/clearToken', null, { root: true });
	},
	forgotPassword(_, { email }) {
		return forgotPassword(email);
	},
	resetPassword(_, payload) {
		return resetPassword(payload);
	},
	getUserToken(_, userId) {
		return getUserToken(userId);
	},
};

// mutations
const mutations = {
	setToken(state, { token, rememberMe }) {
		const cookieOptions = {
			SameSite: 'strict',
			secure: 'https:' === document.location.protocol,
		};

		if (rememberMe) {
			cookieOptions.expires = rememberMeDays;
		}

		VueCookie.set(tokenKey, token, cookieOptions);
		state.token = token;
	},
	clearToken(state) {
		VueCookie.delete(tokenKey);
		state.token = null;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
