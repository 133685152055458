<template>
	<component :is="layout" v-show="ready">
		<ErrorListener>
			<router-view />
		</ErrorListener>
	</component>
</template>

<script>
import { mapGetters } from 'vuex';
import Layout from '@/components/layout/Layout';
import PublicLayout from '@/components/layout/PublicLayout';
import NoLayout from '@/components/layout/NoLayout';
import ErrorListener from '@/components/layout/ErrorListener';

export default {
	name: 'App',

	components: {
		'default-layout': Layout,
		'public-layout': PublicLayout,
		'no-layout': NoLayout,
		ErrorListener,
	},

	data: () => ({
		ready: false,
	}),

	computed: {
		...mapGetters('auth', ['isAuthenticated']),
		layout() {
			if (this.$route.meta.layout) {
				return `${this.$route.meta.layout}-layout`;
			}
			return this.isAuthenticated ? 'default-layout' : 'public-layout';
		},
	},

	mounted() {
		if (document.readyState === 'complete') {
			this.ready = true;
			return;
		}
		window.addEventListener('load', () => (this.ready = true));
	},
};
</script>

<style lang="scss">
@import '@/assets/sass/styles.scss';
</style>
