<template>
	<NotFound />
</template>

<script>
import NotFound from '@/components/errors/NotFound';

export default {
	name: 'NotFoundView',
	components: {
		NotFound,
	},
};
</script>
