import { crud } from '@/api/media';
import { uploadAudioFile } from '@/api/audio';
import { getAudioFiles } from '@/api/audio';
import { getPaginatedAudioFiles } from '@/api/audio';

const state = {};

const getters = {};

const actions = {
	getMediaList() {
		return crud.all();
	},
	getMedia(_, mediaId) {
		return crud.find(mediaId);
	},
	createMedia(_, payload) {
		return crud.create(payload);
	},
	updateMedia(_, { mediaId, payload }) {
		return crud.update(mediaId, payload);
	},
	uploadAudioFile(_, payload) {
		return uploadAudioFile(payload);
	},
	getAudioFiles(_, params) {
		return getAudioFiles(params);
	},
	getPaginatedAudioFiles(_, { params }) {
		return getPaginatedAudioFiles(params);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
