import http from './http';

export default class CrudApi {
	constructor(url, options = {}) {
		this.baseUrl = url;
		const defaultRoutes = ['index', 'show', 'store', 'update', 'destroy'];
		this.routes = Array.isArray(options.only)
			? options.only.filter(route => defaultRoutes.indexOf(route) >= 0)
			: defaultRoutes;
	}

	url(parts) {
		if (Object.keys(parts).length === 0) {
			return this.baseUrl;
		}
		const regex = new RegExp(':(' + Object.keys(parts).join('|') + ')', 'g');
		return this.baseUrl.replace(regex, (m, $1) => parts[$1] || m);
	}

	all(params = {}, urlParts = {}) {
		this.verifyRoute('index');
		return http.get(this.url(urlParts), { params }).then(resp => resp.data);
	}

	find(id, urlParts = {}) {
		this.verifyRoute('show');
		return http.get(`${this.url(urlParts)}/${id}`).then(resp => resp.data.data);
	}

	create(payload, urlParts = {}) {
		this.verifyRoute('store');
		return http
			.post(this.url(urlParts), payload, { headers: this.getDefaultHeaders(payload) })
			.then(resp => resp.data);
	}

	update(id, payload, urlParts = {}) {
		this.verifyRoute('update');
		return http
			.put(`${this.url(urlParts)}/${id}`, payload, { headers: this.getDefaultHeaders(payload) })
			.then(resp => resp.data);
	}

	delete(id, urlParts = {}) {
		this.verifyRoute('destroy');
		return http.delete(`${this.url(urlParts)}/${id}`).then(resp => resp.data?.data);
	}

	getDefaultHeaders(payload) {
		let headers = {};
		if (payload instanceof FormData) {
			headers['Content-Type'] = 'multipart/form-data';
		}
		return headers;
	}

	verifyRoute(routeName) {
		if (this.routes.indexOf(routeName) === -1) {
			throw `Route does not exist (${this.baseUrl}): ${routeName}`;
		}
	}
}
