import { crud } from '@/api/articles';

const state = {};

const getters = {};

const actions = {
	getArticles() {
		return crud.all();
	},
	getArticle(_, articleId) {
		return crud.find(articleId);
	},
	createArticle(_, payload) {
		return crud.create(payload);
	},
	updateArticle(_, { articleId, payload }) {
		return crud.update(articleId, payload);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
