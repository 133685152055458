const storage = localStorage;

export function getCachedItem(key) {
	const item = storage.getItem(key);
	if (!item) {
		return null;
	}

	try {
		return JSON.parse(item);
	} catch (e) {
		forgetItem(item);
	}
	return null;
}

export function cacheItem(key, item) {
	storage.setItem(key, JSON.stringify(item));
}

export function forgetItem(key) {
	try {
		storage.removeItem(key);
	} catch (e) {
		// Do nothing
	}
}
