import { getInstitutionExportUsers, getInstitutionExportGroups } from '@/api/unilogin';

const state = {};

const getters = {};

const actions = {
	getInstitutionExportUsers(_, { params, institutionId }) {
		return getInstitutionExportUsers(institutionId, params);
	},
	getInstitutionExportGroups(_, { params, institutionId }) {
		return getInstitutionExportGroups(institutionId, params);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
