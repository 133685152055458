import { crud, updateUserPassword, confirmUserInformation } from '@/api/users';

const state = {};

const getters = {};

const actions = {
	getUsers(_, params) {
		return crud.all(params);
	},
	getUser(_, userId) {
		return crud.find(userId);
	},
	createUser(_, payload) {
		return crud.create(payload);
	},
	updateUser(_, { userId, payload }) {
		return crud.update(userId, payload);
	},
	updateUserPassword(_, { userId, payload }) {
		return updateUserPassword(userId, payload);
	},
	deleteUser(_, userId) {
		return crud.delete(userId);
	},
	confirmUserInformation(_, { userId, confirmed }) {
		return confirmUserInformation(userId, confirmed);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
