import {
	getUserStats,
	getOnlineUserStats,
	getTopUserStats,
	getScreeningStats,
	getAnswerStats,
	getEmailStats,
} from '@/api/systemstats';

const state = {};

const getters = {};

const actions = {
	getUserStats() {
		return getUserStats();
	},
	getOnlineUserStats() {
		return getOnlineUserStats();
	},
	getTopUserStats() {
		return getTopUserStats();
	},
	getScreeningStats() {
		return getScreeningStats();
	},
	getAnswerStats() {
		return getAnswerStats();
	},
	getEmailStats() {
		return getEmailStats();
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
