<template>
	<div class="app">
		<slot />
	</div>
</template>

<script>
export default {
	name: 'NoLayout',
};
</script>
