// This store keeps track of query params for each route.
// This makes it possible to link back to a previous route with the same query params.

const state = () => ({
	queryParams: {},
});

const getters = {
	query: state => name => {
		return state.queryParams[name] || {};
	},
};

const actions = {
	storeRoute({ commit }, { route }) {
		if (!route.name) {
			return;
		}

		if (Object.keys(route.query).length > 0) {
			commit('setQuery', { name: route.name, query: route.query });
		} else {
			commit('clearQuery', { name: route.name });
		}
	},
};

const mutations = {
	setQuery(state, { name, query }) {
		state.queryParams = { ...state.queryParams, [name]: query };
	},
	clearQuery(state, { name }) {
		const queryParams = state.queryParams;
		delete queryParams[name];
		state.queryParams = queryParams;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
