import http from './http';
import CrudApi from './crud';

export const crud = new CrudApi('/admin/sections', { only: ['update', 'store'] });

export function attachMedia(sectionId, mediaId, conf) {
	return http.post(`/admin/sections/${sectionId}/media/${mediaId}`, { conf }).then(resp => resp.data);
}

export function detachMedia(sectionId, mediaId) {
	return http.delete(`/admin/sections/${sectionId}/media/${mediaId}`).then(resp => resp.data);
}

export function attachTask(sectionId, taskId) {
	return http.post(`/admin/sections/${sectionId}/tasks/${taskId}`).then(resp => resp.data);
}

export function detachTask(sectionId, taskId) {
	return http.delete(`/admin/sections/${sectionId}/tasks/${taskId}`).then(resp => resp.data);
}

export function getTypes() {
	return http.get('/admin/sections/types').then(({ data }) => data.data);
}

export function getExerciseDuration(sectionId, courseId) {
	return http
		.get(`/admin/sections/${sectionId}/exercise_duration`, { params: { course_id: courseId } })
		.then(resp => resp.data);
}

export function attachToParent(
	section_id,
	section_type_id,
	section_relations_type,
	section_relations_id,
	section_relation_id = null,
) {
	const payload = { section_type_id, section_relations_type, section_relations_id };
	if (section_relation_id) {
		payload.section_relation_id = section_relation_id;
	}
	return http.post(`/admin/sections/${section_id}/attach`, payload).then(resp => resp.data);
}
