import http from './http';
import CrudApi from './crud';

export const crud = new CrudApi('/admin/books');

export function uploadCoverImages(bookId, payload) {
	return http
		.post(`/admin/books/${bookId}/coverimages`, payload, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
		.then(resp => resp.data);
}

export function updateWeights(payload) {
	return http.put('/admin/books/weights', payload).then(resp => resp.data);
}

export function getBook(bookId) {
	return http.get(`/admin/books/${bookId}`).then(resp => resp.data.data);
}

export function getSitemap(bookId) {
	return http.get(`/admin/books/${bookId}/sitemap`).then(resp => resp.data.data);
}

export function getContentAnalysis(bookId) {
	return http.get(`/admin/books/${bookId}/contentanalysis`).then(resp => resp.data.data);
}

export function getSection(bookId, sectionRelationId) {
	return http.get(`/admin/books/${bookId}/sections/${sectionRelationId}`).then(resp => resp.data.data);
}
