import Router from 'vue-router';
import { ClientError, RequestError, NetworkError, OfflineError } from '@/api/errors';

export function shouldErrorStopPropagation(err) {
	return (
		err instanceof ClientError ||
		err instanceof RequestError ||
		err instanceof OfflineError ||
		err instanceof NetworkError ||
		Router.isNavigationFailure(err)
	);
}
