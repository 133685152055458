import { crud, updateWeights } from '@/api/screeningassignmenttypes';

const state = {};

const getters = {};

const actions = {
	getScreeningAssignmentTypes() {
		return crud.all();
	},
	createScreeningAssignmentType(_, payload) {
		return crud.create(payload);
	},
	getScreeningAssignmentType(_, typeId) {
		return crud.find(typeId);
	},
	updateScreeningAssignmentType(_, { typeId, payload }) {
		return crud.update(typeId, payload);
	},
	deleteScreeningAssignmentType(_, typeId) {
		return crud.delete(typeId);
	},
	updateWeights(_, payload) {
		return updateWeights(payload);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
