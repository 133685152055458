import { crud } from '@/api/educations';

const state = {};

const getters = {};

const actions = {
	getEducations() {
		return crud.all();
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
