import http from './http';

export function getTrials() {
	return http.get('/admin/bundles/trials').then(resp => resp.data.data);
}

export function updateSchoolBundles(schoolId, payload) {
	return http.post(`/admin/schools/${schoolId}/bundles`, payload).then(resp => resp.data);
}

export function updateUserBundles(userId, schoolId, payload) {
	return http.post(`/admin/schools/${schoolId}/users/${userId}/bundles`, payload).then(resp => resp.data);
}

export function extendUserBundleExpirationDates(userId, schoolId, expiration_date) {
	return http
		.put(`/admin/schools/${schoolId}/users/${userId}/extend-bundles`, { expiration_date })
		.then(resp => resp.data);
}
