import { getImages, getPaginatedImages, uploadImage } from '@/api/images';

const state = {};

const getters = {};

const actions = {
	getImages(_, { params }) {
		return getImages(params);
	},
	getPaginatedImages(_, { params }) {
		return getPaginatedImages(params);
	},
	uploadImage(_, { payload }) {
		return uploadImage(payload);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
