import http from './http';
import CrudApi from './crud';

export const crud = new CrudApi('/admin/schools/:schoolId/classes');

export function restoreSchoolClass(schoolId, classId) {
	return http.put(`/admin/schools/${schoolId}/classes/${classId}/restore`).then(resp => resp.data);
}

export function syncClassFromUniLogin(schoolId, classId) {
	return http.post(`/schools/${schoolId}/classes/${classId}/syncfromunilogin`).then(resp => resp.data);
}
