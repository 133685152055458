import {
	crud,
	uploadCoverImages,
	updateWeights,
	getBook,
	getSection,
	getSitemap,
	getContentAnalysis,
} from '@/api/books';

const state = {};

const getters = {};

const actions = {
	getBooks() {
		return crud.all();
	},
	getBook(_, bookId) {
		return getBook(bookId);
	},
	getSitemap(_, bookId) {
		return getSitemap(bookId);
	},
	getContentAnalysis(_, bookId) {
		return getContentAnalysis(bookId);
	},
	createBook(_, payload) {
		return crud.create(payload);
	},
	updateBook(_, { bookId, payload }) {
		return crud.update(bookId, payload);
	},
	deleteBook(_, bookId) {
		return crud.delete(bookId);
	},
	uploadCoverImages(_, { bookId, payload }) {
		return uploadCoverImages(bookId, payload);
	},
	updateWeights(_, payload) {
		return updateWeights(payload);
	},
	getSection(_, { bookId, sectionRelationId }) {
		return getSection(bookId, sectionRelationId);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
