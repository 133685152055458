import http from './http';

export function getUserStats() {
	return http.get('/admin/systemstats/users').then(({ data }) => data.data);
}

export function getOnlineUserStats() {
	return http.get('/admin/systemstats/online-users').then(({ data }) => data.data);
}

export function getTopUserStats() {
	return http.get('/admin/systemstats/top-users').then(({ data }) => data.data);
}

export function getScreeningStats() {
	return http.get('/admin/systemstats/screenings').then(({ data }) => data.data);
}

export function getAnswerStats() {
	return http.get('/admin/systemstats/answers').then(({ data }) => data.data);
}

export function getEmailStats() {
	return http.get('/admin/systemstats/emails').then(({ data }) => data.data);
}
