import {
	crud,
	restoreScreeningAssignment,
	uploadImage,
	updateWeights,
	getSitemap,
	getContentAnalysis,
	getDurationTotal,
} from '@/api/screeningassignments';

const state = {};

const getters = {};

const actions = {
	getScreeningAssignments() {
		return crud.all();
	},
	getScreeningAssignment(_, screeningAssignmentId) {
		return crud.find(screeningAssignmentId);
	},
	createScreeningAssignment(_, payload) {
		return crud.create(payload);
	},
	updateScreeningAssignment(_, { screeningAssignmentId, payload }) {
		return crud.update(screeningAssignmentId, payload);
	},
	deleteScreeningAssignment(_, screeningAssignmentId) {
		return crud.delete(screeningAssignmentId);
	},
	restoreScreeningAssignment(_, screeningAssignmentId) {
		return restoreScreeningAssignment(screeningAssignmentId);
	},
	uploadImage(_, { screeningAssignmentId, payload }) {
		return uploadImage(screeningAssignmentId, payload);
	},
	updateWeights(_, payload) {
		return updateWeights(payload);
	},
	getSitemap(_, screeningAssignmentId) {
		return getSitemap(screeningAssignmentId);
	},
	getContentAnalysis(_, screeningAssignmentId) {
		return getContentAnalysis(screeningAssignmentId);
	},
	getDurationTotal(_, { screeningAssignmentId }) {
		return getDurationTotal(screeningAssignmentId);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
