import http from './http';
import CrudApi from './crud';

export const crud = new CrudApi('/admin/schools');

export function detachUser(schoolId, userId) {
	return http.delete(`/admin/schools/${schoolId}/users/${userId}`).then(resp => resp.data);
}

export function attachUser(schoolId, userId, role) {
	return http.post(`/admin/schools/${schoolId}/users/${userId}`, { role }).then(resp => resp.data);
}

export function mergeSchools(dstSchoolId, srcSchoolIds, safeword) {
	return http
		.put(`/admin/schools/${dstSchoolId}/mergeschools`, {
			school_ids: srcSchoolIds,
			safeword,
		})
		.then(resp => resp.data);
}
