import { crud, uploadFlagImage, updateWeights } from '@/api/courses';

const state = () => ({
	courses: [],
});

const getters = {};

const actions = {
	getCourses({ commit }) {
		return crud.all().then(({ data }) => {
			commit('setCourses', data);
			return data.slice(0);
		});
	},

	getCourse(_, courseId) {
		return crud.find(courseId);
	},

	createCourse(_, payload) {
		return crud.create(payload);
	},

	updateCourse(_, { courseId, payload }) {
		return crud.update(courseId, payload);
	},

	uploadFlagImage(_, { courseId, payload }) {
		return uploadFlagImage(courseId, payload);
	},

	updateWeights(_, payload) {
		return updateWeights(payload);
	},
};

const mutations = {
	setCourses(state, courses) {
		state.courses = courses;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
