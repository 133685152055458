import { crud } from '@/api/tags';

const state = {};

const getters = {};

const actions = {
	getTags(_, params) {
		return crud.all(params);
	},
	getTag(_, tagId) {
		return crud.find(tagId);
	},
	createTag(_, payload) {
		return crud.create(payload);
	},
	updateTag(_, { tagId, payload }) {
		return crud.update(tagId, payload);
	},
	deleteTag(_, tagId) {
		return crud.delete(tagId);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
