import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import App from './App.vue';

import VueCookie from 'vue-cookie';
Vue.use(VueCookie);

import Buefy from 'buefy';
Vue.use(Buefy, {
	defaultIconPack: 'fas',
	defaultToastPosition: 'is-bottom-right',
	defaultToastDuration: 5000,
});

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

import vClickOutside from 'v-click-outside';
Vue.use(vClickOutside);

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

import router from './router';
import store from './store';

Vue.config.productionTip = false;

import * as Sentry from '@sentry/vue';

import PusherService from './libs/PusherService';

import { shouldErrorStopPropagation } from './utils/error';

// Configure the app before bootstrapping.
store.dispatch('app/getConfig').then(config => {
	Sentry.init({
		Vue,
		dsn: config.sentryDSN,
		integrations: [
			new Sentry.BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracingOrigins: ['localhost', config.apiURL, /^\//],
			}),
		],
		environment: config.environment,
		enabled: !!config.sentryDSN,
		ignoreErrors: [
			/ResizeObserver/i,
			/Redirected when going from/i,
			/Avoided redundant navigation/i,
			/Navigation cancelled/i,
			/Loading CSS chunk/i,
			/Loading chunk/i,
		],
		beforeSend(event, hint) {
			const err = hint.originalException;
			if (shouldErrorStopPropagation(err)) {
				return null;
			}

			if (store.state.user.user) {
				event.user = {
					id: store.state.user.user.id,
					username: store.state.user.user.name,
					email: store.state.user.user.email,
				};
			}
			return event;
		},
		beforeSendTransaction(event, hint) {
			const err = hint.originalException;
			if (shouldErrorStopPropagation(err)) {
				return null;
			}
			return event;
		},
	});

	if (config?.pusher?.appKey) {
		PusherService.init(config.pusher.appKey, config.pusher.host, config.apiURL);
	}

	new Vue({
		router,
		store,
		render: h => h(App),
	}).$mount('#app');
});
