import { getUserActivity, getSchoolInsights, getTeacherInsights } from '@/api/activity';

const state = {};

const getters = {};

const actions = {
	getUserActivity(_, userId) {
		return getUserActivity(userId);
	},
	getSchoolInsights(_, params) {
		return getSchoolInsights(params);
	},
	getTeacherInsights(_, { schoolId, params }) {
		return getTeacherInsights(schoolId, params);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
