import { getAppConfig, doAppSearch } from '../../api/app';
import { getCachedItem, cacheItem } from '../cache';

const configCacheKey = 'config';

const state = () => ({
	config: getCachedItem(configCacheKey) || null,
});

const actions = {
	getConfig({ state, commit }) {
		if (state.config !== null) {
			return state.config;
		}

		return getAppConfig().then(config => {
			commit('setConfig', config);
			return config;
		});
	},
	doAppSearch(_, params) {
		return doAppSearch(params);
	},
};

const mutations = {
	setConfig(state, config) {
		state.config = config;
		cacheItem(configCacheKey, config);
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
