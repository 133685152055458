import { updateUserBundles, updateSchoolBundles, getTrials, extendUserBundleExpirationDates } from '@/api/bundles';

const state = {};

const getters = {};

const actions = {
	getTrials() {
		return getTrials();
	},
	updateUserBundles(_, { userId, schoolId, payload }) {
		return updateUserBundles(userId, schoolId, payload);
	},
	updateSchoolBundles(_, { schoolId, payload }) {
		return updateSchoolBundles(schoolId, payload);
	},
	extendUserBundleExpirationDates(_, { userId, schoolId, expiration_date }) {
		return extendUserBundleExpirationDates(userId, schoolId, expiration_date);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
