import http from './http';

export function getImages(params) {
	return http.get('/admin/images', { params }).then(resp => resp.data.data);
}

export function getPaginatedImages(params) {
	return http.get('/admin/images/paginate', { params }).then(resp => resp.data);
}

export function uploadImage(payload) {
	return http
		.post('/admin/images', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then(resp => resp.data);
}
