<template>
	<div class="app has-background-primary">
		<section class="section public-wrapper">
			<div class="container">
				<slot />
			</div>
		</section>
	</div>
</template>

<script>
export default {
	name: 'PublicLayout',
};
</script>

<style>
.app .public-wrapper {
	padding-top: 8rem;
}
</style>
