import SubLayout from '@/components/layout/SubLayout';
import NotFound from '@/views/error/NotFound';

export default [
	{
		path: '/',
		name: 'home',
		component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
	},

	{
		path: '/profile',
		component: SubLayout,
		children: [
			{
				path: '/',
				name: 'profile',
				component: () => import(/* webpackChunkName: "dashboard" */ '@/views/user/Profile.vue'),
			},
		],
	},

	// User routes
	{
		path: '/users',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'users.index',
				component: () => import(/* webpackChunkName: "users" */ '@/views/users/List.vue'),
			},
			{
				path: ':id',
				name: 'users.edit',
				component: () => import(/* webpackChunkName: "users" */ '@/views/users/Edit.vue'),
			},
		],
	},

	// Email routes
	{
		path: '/email',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'email.index',
				component: () => import(/* webpackChunkName: "email" */ '@/views/email/logs/List.vue'),
			},
		],
	},

	// Article routes
	{
		path: '/articles',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'articles.index',
				component: () => import(/* webpackChunkName: "articles" */ '@/views/articles/List.vue'),
			},
			{
				path: 'create',
				name: 'articles.create',
				component: () =>
					import(/* webpackChunkName: "articles" */ '@/views/articles/Create.vue'),
			},
			{
				path: ':id',
				name: 'articles.edit',
				component: () => import(/* webpackChunkName: "articles" */ '@/views/articles/Edit.vue'),
			},
		],
	},

	// Media routes
	{
		path: '/media',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'media.index',
				component: () => import(/* webpackChunkName: "media" */ '@/views/media/List.vue'),
			},
			{
				path: 'create',
				name: 'media.create',
				component: () => import(/* webpackChunkName: "media" */ '@/views/media/Create.vue'),
			},
			{
				path: ':id',
				name: 'media.edit',
				component: () => import(/* webpackChunkName: "media" */ '@/views/media/Edit.vue'),
			},
		],
	},

	// Insights routes
	{
		path: '/insights',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'insights.index',
				component: () => import(/* webpackChunkName: "insights" */ '@/views/insights/List.vue'),
			},
			{
				path: 'schools/:id',
				name: 'insights.school',
				component: () =>
					import(/* webpackChunkName: "insights" */ '@/views/insights/School.vue'),
			},
		],
	},

	// School routes
	{
		path: '/schools',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'schools.index',
				component: () => import(/* webpackChunkName: "schools" */ '@/views/schools/List.vue'),
			},
			{
				path: 'create',
				name: 'schools.create',
				component: () => import(/* webpackChunkName: "schools" */ '@/views/schools/Create.vue'),
			},
			{
				path: ':id',
				name: 'schools.edit',
				component: () => import(/* webpackChunkName: "schools" */ '@/views/schools/Edit.vue'),
			},
			{
				path: ':schoolId/teachers/create',
				name: 'schools.teachers.create',
				component: () =>
					import(/* webpackChunkName: "schools" */ '@/views/schools/CreateTeacher.vue'),
			},

			// School class routes
			{
				path: ':schoolId/classes/create',
				name: 'schools.classes.create',
				component: () =>
					import(/* webpackChunkName: "schools" */ '@/views/schoolclasses/Create.vue'),
			},
			{
				path: ':schoolId/classes/:classId',
				name: 'schools.classes.edit',
				component: () =>
					import(/* webpackChunkName: "schools" */ '@/views/schoolclasses/Edit.vue'),
			},
		],
	},

	// Teacher export routes
	{
		path: '/export',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'export.index',
				component: () => import(/* webpackChunkName: "export" */ '@/views/export/Index.vue'),
			},
		],
	},

	// Achievements routes
	{
		path: '/achievements',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'achievements.index',
				component: () =>
					import(/* webpackChunkName: "export" */ '@/views/achievements/List.vue'),
			},
			{
				path: 'create',
				name: 'achievements.create',
				component: () =>
					import(
						/* webpackChunkName: "achievements" */ '@/views/achievements/Create.vue'
					),
			},
			{
				path: ':id',
				name: 'achievements.edit',
				component: () =>
					import(/* webpackChunkName: "achievements" */ '@/views/achievements/Edit.vue'),
			},
		],
	},

	// Author routes
	{
		path: '/authors',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'authors.index',
				component: () => import(/* webpackChunkName: "authors" */ '@/views/authors/List.vue'),
			},
			{
				path: 'create',
				name: 'authors.create',
				component: () => import(/* webpackChunkName: "authors" */ '@/views/authors/Create.vue'),
			},
			{
				path: ':id',
				name: 'authors.edit',
				component: () => import(/* webpackChunkName: "authors" */ '@/views/authors/Edit.vue'),
			},
		],
	},

	// Course routes
	{
		path: '/courses',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'courses.index',
				component: () => import(/* webpackChunkName: "courses" */ '@/views/courses/List.vue'),
			},
			{
				path: 'create',
				name: 'courses.create',
				component: () => import(/* webpackChunkName: "courses" */ '@/views/courses/Create.vue'),
			},
			{
				path: ':id',
				name: 'courses.edit',
				component: () => import(/* webpackChunkName: "courses" */ '@/views/courses/Edit.vue'),
			},
		],
	},

	// Taxonomy routes
	{
		path: '/taxonomy',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'taxonomy.index',
				component: () => import(/* webpackChunkName: "taxonomy" */ '@/views/taxonomy/List.vue'),
			},
			{
				path: 'category/create',
				name: 'taxonomy.category.create',
				component: () =>
					import(
						/* webpackChunkName: "taxonomy" */ '@/views/taxonomy/CreateCategory.vue'
					),
			},
			{
				path: 'category/:id',
				name: 'taxonomy.category.edit',
				component: () =>
					import(/* webpackChunkName: "taxonomy" */ '@/views/taxonomy/EditCategory.vue'),
			},
			{
				path: 'tag/create',
				name: 'taxonomy.tag.create',
				component: () =>
					import(/* webpackChunkName: "taxonomy" */ '@/views/taxonomy/CreateTag.vue'),
			},
			{
				path: 'tag/:id',
				name: 'taxonomy.tag.edit',
				component: () =>
					import(/* webpackChunkName: "taxonomy" */ '@/views/taxonomy/EditTag.vue'),
			},
		],
	},

	// Notification routes
	{
		path: '/notifications',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'notifications.index',
				component: () =>
					import(
						/* webpackChunkName: "notifications" */ '@/views/notifications/List.vue'
					),
			},
			{
				path: 'create',
				name: 'notifications.create',
				component: () =>
					import(
						/* webpackChunkName: "notifications" */ '@/views/notifications/Create.vue'
					),
			},
			{
				path: ':id',
				name: 'notifications.edit',
				component: () =>
					import(
						/* webpackChunkName: "notifications" */ '@/views/notifications/Edit.vue'
					),
			},
		],
	},

	// App news routes
	{
		path: '/news',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'news.index',
				component: () => import(/* webpackChunkName: "news" */ '@/views/news/List.vue'),
			},
			{
				path: 'create',
				name: 'news.create',
				component: () => import(/* webpackChunkName: "news" */ '@/views/news/Create.vue'),
			},
			{
				path: ':id',
				name: 'news.edit',
				component: () => import(/* webpackChunkName: "news" */ '@/views/news/Edit.vue'),
			},
		],
	},

	// Book routes
	{
		path: '/books',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'books.index',
				component: () => import(/* webpackChunkName: "books" */ '@/views/books/List.vue'),
			},
			{
				path: 'create',
				name: 'books.create',
				component: () => import(/* webpackChunkName: "books" */ '@/views/books/Create.vue'),
			},
			{
				path: ':id',
				name: 'books.edit',
				component: () => import(/* webpackChunkName: "books" */ '@/views/books/Edit.vue'),
			},
		],
	},

	// Tooltip routes
	{
		path: '/tooltips',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'tooltips.index',
				component: () => import(/* webpackChunkName: "tooltips" */ '@/views/tooltips/List.vue'),
			},
			{
				path: 'create',
				name: 'tooltips.create',
				component: () =>
					import(/* webpackChunkName: "tooltips" */ '@/views/tooltips/Create.vue'),
			},
			{
				path: ':id',
				name: 'tooltips.edit',
				component: () => import(/* webpackChunkName: "tooltips" */ '@/views/tooltips/Edit.vue'),
			},
		],
	},

	// References routes
	{
		path: '/references',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'references.index',
				component: () =>
					import(/* webpackChunkName: "references" */ '@/views/references/List.vue'),
			},
		],
	},

	// Bundle routes
	{
		path: '/bundles/trials',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'bundles.trials',
				component: () => import(/* webpackChunkName: "bundles" */ '@/views/bundles/Trials.vue'),
			},
		],
	},

	// Assignment routes
	{
		path: '/assignments',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'assignments.index',
				component: () =>
					import(/* webpackChunkName: "assignments" */ '@/views/assignments/List.vue'),
			},
			{
				path: 'create',
				name: 'assignments.create',
				component: () =>
					import(/* webpackChunkName: "assignments" */ '@/views/assignments/Create.vue'),
			},
			{
				path: ':id',
				name: 'assignments.edit',
				component: () =>
					import(/* webpackChunkName: "assignments" */ '@/views/assignments/Edit.vue'),
			},
		],
	},

	// AssignmentType routes
	{
		path: '/assignmenttypes',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'assignmenttypes.index',
				component: () =>
					import(
						/* webpackChunkName: "assignmenttypes" */ '@/views/assignmenttypes/List.vue'
					),
			},
			{
				path: 'create',
				name: 'assignmenttypes.create',
				component: () =>
					import(
						/* webpackChunkName: "assignmenttypes" */ '@/views/assignmenttypes/Create.vue'
					),
			},
			{
				path: ':id',
				name: 'assignmenttypes.edit',
				component: () =>
					import(
						/* webpackChunkName: "assignmenttypes" */ '@/views/assignmenttypes/Edit.vue'
					),
			},
		],
	},

	// ScreeningAssignmentType routes
	{
		path: '/screeningassignmenttypes',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'screeningassignmenttypes.index',
				component: () =>
					import(
						/* webpackChunkName: "screeningassignmenttypes" */ '@/views/screeningassignmenttypes/List.vue'
					),
			},
			{
				path: 'create',
				name: 'screeningassignmenttypes.create',
				component: () =>
					import(
						/* webpackChunkName: "screeningassignmenttypes" */ '@/views/screeningassignmenttypes/Create.vue'
					),
			},
			{
				path: ':id',
				name: 'screeningassignmenttypes.edit',
				component: () =>
					import(
						/* webpackChunkName: "screeningassignmenttypes" */ '@/views/screeningassignmenttypes/Edit.vue'
					),
			},
		],
	},

	// Screening routes
	{
		path: '/screeningassignments',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'screeningassignments.index',
				component: () =>
					import(
						/* webpackChunkName: "screeningassignments" */ '@/views/screeningassignments/List.vue'
					),
			},
			{
				path: 'create',
				name: 'screeningassignments.create',
				component: () =>
					import(
						/* webpackChunkName: "screeningassignments" */ '@/views/screeningassignments/Create.vue'
					),
			},
			{
				path: ':id',
				name: 'screeningassignments.edit',
				component: () =>
					import(
						/* webpackChunkName: "screeningassignments" */ '@/views/screeningassignments/Edit.vue'
					),
			},
		],
	},

	// Task routes
	{
		path: '/tasks',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'tasks.index',
				component: () => import(/* webpackChunkName: "tasks" */ '@/views/tasks/List.vue'),
			},
			{
				path: ':id',
				name: 'tasks.edit',
				component: () => import(/* webpackChunkName: "tasks" */ '@/views/tasks/Edit.vue'),
			},
		],
	},

	// SectionReation routes
	{
		path: '/sectionrelations',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'sectionrelations.index',
				component: () =>
					import(
						/* webpackChunkName: "sectionrelations" */ '@/views/sectionrelations/List.vue'
					),
			},
			{
				path: ':id',
				name: 'sectionrelations.edit',
				component: () =>
					import(
						/* webpackChunkName: "sectionrelations" */ '@/views/sectionrelations/Edit.vue'
					),
			},
		],
	},

	{
		path: '/schoolmerger',
		component: SubLayout,
		children: [
			{
				path: '',
				name: 'schoolmerger',
				component: () =>
					import(/* webpackChunkName: "system" */ '@/views/system/SchoolMerger.vue'),
			},
		],
	},

	// System stats
	{
		path: '/systemstats',
		name: 'systemstats',
		component: () => import(/* webpackChunkName: "systemstats" */ '@/views/systemstats/Dashboard.vue'),
		meta: { layout: 'no' },
	},

	// Lix Dictionary administration
	{
		path: '/dictionary',
		component: SubLayout,
		children: [
			{
				path: '',
				redirect: { name: 'dictionary.search' },
			},

			{
				path: 'search',
				name: 'dictionary.search',
				component: () =>
					import(/* webpackChunkName: "dictionary" */ '@/views/dictionary/Search.vue'),
			},
			{
				path: 'feedback',
				name: 'dictionary.feedback',
				component: () =>
					import(
						/* webpackChunkName: "dictionary" */ '@/views/dictionary/feedback/List.vue'
					),
			},
			{
				path: 'search-history',
				name: 'dictionary.search-history',
				component: () =>
					import(
						/* webpackChunkName: "dictionary" */ '@/views/dictionary/search-history/List.vue'
					),
			},
			{
				path: 'search-terms-by-frequency',
				name: 'dictionary.search-terms-by-frequency',
				component: () =>
					import(
						/* webpackChunkName: "dictionary" */ '@/views/dictionary/search-terms-by-frequency/List.vue'
					),
			},
			{
				path: 'entries/:id(\\d+)',
				name: 'dictionary.entry',
				component: () =>
					import(
						/* webpackChunkName: "dictionary" */ '@/views/dictionary/entries/Edit.vue'
					),
			},
			{
				path: 'entries/:language',
				name: 'dictionary.entries',
				component: () =>
					import(
						/* webpackChunkName: "dictionary" */ '@/views/dictionary/entries/List.vue'
					),
			},
			{
				path: 'entries/:language/create',
				name: 'dictionary.create-entry',
				component: () =>
					import(
						/* webpackChunkName: "dictionary" */ '@/views/dictionary/entries/Create.vue'
					),
			},
		],
	},

	// Auth routes
	{
		path: '/login',
		name: 'login',
		component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Login.vue'),
		meta: { public: true },
	},
	{
		path: '/adgangskode/nulstil',
		name: 'forgot-password',
		component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ForgotPassword.vue'),
		meta: { public: true },
	},
	{
		path: '/adgangskode/nulstil/:token',
		name: 'reset-password',
		component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/ResetPassword.vue'),
		meta: { public: true },
	},

	// 404 catch all
	{
		path: '*',
		name: 'not-found',
		component: NotFound,
	},
];
