import { crud as classesCrud, syncClassFromUniLogin, restoreSchoolClass } from '@/api/schoolclasses';
import { crud as studentsCrud } from '@/api/schoolclassstudents';
import { crud as teachersCrud } from '@/api/schoolclassteachers';
import { crud as actionsCrud } from '@/api/schoolclassactions';

const state = {};

const getters = {};

const actions = {
	getSchoolClasses(_, { params, urlParts }) {
		return classesCrud.all(params, urlParts);
	},
	getSchoolClass(_, { classId, urlParts }) {
		return classesCrud.find(classId, urlParts);
	},
	createSchoolClass(_, { payload, urlParts }) {
		return classesCrud.create(payload, urlParts);
	},
	updateSchoolClass(_, { classId, payload, urlParts }) {
		return classesCrud.update(classId, payload, urlParts);
	},
	deleteSchoolClass(_, { classId, urlParts }) {
		return classesCrud.delete(classId, urlParts);
	},
	getStudents(_, urlParts) {
		return studentsCrud.all({}, urlParts);
	},
	removeStudent(_, { studentId, urlParts }) {
		return studentsCrud.delete(studentId, urlParts);
	},
	addTeacher(_, { payload, urlParts }) {
		return teachersCrud.create(payload, urlParts);
	},
	removeTeacher(_, { teacherId, urlParts }) {
		return teachersCrud.delete(teacherId, urlParts);
	},
	getActions(_, { urlParts, params }) {
		return actionsCrud.all(params, urlParts);
	},
	deleteAction(_, { actionId, urlParts }) {
		return actionsCrud.delete(actionId, urlParts);
	},
	syncClassFromUniLogin(_, { schoolId, classId }) {
		return syncClassFromUniLogin(schoolId, classId);
	},
	restoreSchoolClass(_, { schoolId, classId }) {
		return restoreSchoolClass(schoolId, classId);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
