import { getLanguages } from '@/api/languages';

const state = () => ({
	languages: [],
});

const getters = {};

const actions = {
	getLanguages({ commit }) {
		return getLanguages().then(languages => {
			commit('setLanguages', languages);
			return languages.slice(0);
		});
	},
};

const mutations = {
	setLanguages(state, languages) {
		state.languages = languages;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
