import { crud } from '@/api/notifications';

const state = {};

const getters = {};

const actions = {
	getAppNotifications() {
		return crud.all();
	},
	getAppNotification(_, notificationId) {
		return crud.find(notificationId);
	},
	createAppNotification(_, payload) {
		return crud.create(payload);
	},
	updateAppNotification(_, { notificationId, payload }) {
		return crud.update(notificationId, payload);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
