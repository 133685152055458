import http from './http';
import CrudApi from './crud';

export const crud = new CrudApi('/admin/screeningassignments');

export function restoreScreeningAssignment(screeningAssignmentId) {
	return http.post(`/admin/screeningassignments/${screeningAssignmentId}/restore`).then(resp => resp.data.data);
}

export function uploadImage(screeningAssignmentId, payload) {
	return http
		.post(`/admin/screeningassignments/${screeningAssignmentId}/image`, payload, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
		.then(resp => resp.data);
}

export function updateWeights(payload) {
	return http.put('/admin/screeningassignments/weights', payload).then(resp => resp.data);
}

export function getSitemap(screeningAssignmentId) {
	return http.get(`/admin/screeningassignments/${screeningAssignmentId}/sitemap`).then(resp => resp.data.data);
}

export function getContentAnalysis(screeningAssignmentId) {
	return http
		.get(`/admin/screeningassignments/${screeningAssignmentId}/contentanalysis`)
		.then(resp => resp.data.data);
}

export function getDurationTotal(screeningAssignmentId) {
	return http.get(`/admin/screeningassignments/${screeningAssignmentId}/duration-total`).then(resp => resp.data);
}
