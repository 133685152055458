import http from './http';
import CrudApi from './crud';

export const crud = new CrudApi('/admin/courses');

export function uploadFlagImage(courseId, payload) {
	return http
		.post(`/admin/courses/${courseId}/flag`, payload, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
		.then(resp => resp.data);
}

export function updateWeights(payload) {
	return http.put('/admin/courses/weights', payload).then(resp => resp.data);
}
