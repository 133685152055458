import { crud, getEmailStats } from '@/api/emaillogs';

const state = {};

const getters = {};

const actions = {
	getEmailLogs(_, params) {
		return crud.all(params);
	},
	getEmailStats() {
		return getEmailStats();
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
