import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import store from '../store';
import routes from './routes';

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	linkActiveClass: 'is-active',
	routes,
});

router.beforeEach((to, from, next) => {
	if (!store.getters['auth/isAuthenticated'] && to.meta.public !== true) {
		next({ name: 'login' });
		return;
	} else if (store.getters['auth/isAuthenticated'] && to.meta.public === true) {
		next({ name: 'dashboard' });
		return;
	}

	store.dispatch('router/storeRoute', { route: to });

	const requests = [];

	if (store.getters['auth/isAuthenticated']) {
		// If we are authenticated but don't have a user in store - get the user
		if (store.state.user.user === null) {
			requests.push(store.dispatch('user/getUser'));
		}
		// Always keep courses loaded
		if (store.state.courses.courses.length === 0) {
			requests.push(store.dispatch('courses/getCourses'));
		}
		// Always keep tooltips loaded
		if (store.state.tooltips.tooltips.length === 0) {
			requests.push(store.dispatch('tooltips/getTooltips'));
		}

		// Always keep languages loaded
		if (store.state.languages.languages.length === 0) {
			requests.push(store.dispatch('languages/getLanguages'));
		}

		// Check if we already have a Lix token
		if (!store.getters['lix/isAuthenticated']) {
			requests.push(store.dispatch('lix/getToken'));
		}
	}

	if (requests.length === 0) {
		next();
		return;
	}

	Promise.all(requests)
		.then(() => next())
		.catch(err => next(err));
});

export default router;
