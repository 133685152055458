import axios from 'axios';
import store from '@/store';
import { makeError } from './errors';

const http = axios.create({
	headers: {
		Accept: 'application/json',
		'X-Requested-With': 'XMLHttpRequest',
		'ML-Ident': 'app-admin',
	},
	timeout: 65000,
	responseType: 'json',
	responseEncoding: 'utf8',
	transitional: {
		clarifyTimeoutError: true,
	},
});

http.interceptors.request.use(
	config => {
		config.baseURL = store.state.app.config.apiURL;
		const token = store.state.auth.token;
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}
		return config;
	},
	err => Promise.reject(makeError(err)),
);

http.interceptors.response.use(
	resp => resp,
	err => Promise.reject(makeError(err)),
);

export default http;
